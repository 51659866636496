<template>
  <div class="d-flex flex-nowrap">
    <div class="d-flex flex-column flex-nowrap">
      <div>
        <span class="nowrap text-truncate">{{reasonName}}</span>
      </div>
      <div
        class="d-flex flex-row ref-detail"
        v-if="innerOptions.showDetail"
      >
        <document-name-ref
          class="nowrap text-truncate"
          v-if="_categoryProps.visible && categoryCount > 1"
          :id="categoryId"
          :cache-type="categoryCacheType"
        />
      </div>
      <slot name="doc-type" />
    </div>

  </div>
</template>

<script>
import ClientCache from '@/mixins/client-cache'

export default {
  inheritAttrs: false,
  components: {
    DocumentNameRef: () => import('@/components/documents-ref/document-name-ref')
  },
  computed: {
    categoryCount () {
      return this.cache?.doc?.categoryCount
    },
    categoryId () {
      return this.cache?.doc?.categoryId
    },
    reasonName () {
      return this.id ? this.cache?.doc?.name : this.$t('t.None')
    },
    _categoryProps () {
      const _ = Object.assign({
        visible: true,
        small: true
      }, this.categoryProps)

      return _
    },
    innerOptions () {
      return {
        showDetail: this.options?.showDetail ?? false
      }
    }
  },
  data () {
    return {
      categoryCacheType: ClientCache.CacheType.DisputeReasonCategoryRef,
      cacheType: ClientCache.CacheType.DisputeReasonRef
    }
  },
  mixins: [ClientCache],
  props: {
    id: String,
    options: Object,
    categoryProps: Object
  }
}
</script>

<style lang="stylus" scoped>
.smallText
  font-size 0.75em
</style>
